import { useRef } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Main from '../components/Main.js';
import OpeningHours from '../components/OpeningHours.js';
import Contact from '../components/Contact.js';

const LandingPage = (props) => {

  const landingPage = useRef();
  const { windowDimensions } = props;
  const { windowWidth } = windowDimensions;

  return (
    <motion.div
      ref={landingPage}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="view view--landing-page"
    >
      <Main {...props} />
      <OpeningHours {...props} />
      <Contact {...props} />
      {
        windowWidth < 768 &&
        <section>
          <Link to={`/privacy-policy`} className="link--privacy-policy--mobile">Privacy Policy</Link>
        </section>
      }
    </motion.div>
  )
}

export default LandingPage;