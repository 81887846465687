import { useState, useEffect, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Switch, Route, BrowserRouter, Link } from 'react-router-dom';
import * as _ from 'underscore';
import '../css/index.css';

import Header from './components/Header.js';
import LandingPage from './views/LandingPage.js';
import PrivacyPolicy from './views/PrivacyPolicy.js';
import { fetchCockpitData } from './utils/cockpit.js';

const Content = (props) => {

  const { pathname } = props.location;
  const [mainData, setMainData] = useState({});
  const [contactData, setContactData] = useState({});
  const [openingHoursData, setOpeningHoursData] = useState({});
  const [privacyPolicyData, setPrivacyPolicyData] = useState({});
  const [windowDimensions, setWindowDimensions] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  });

  const isScrolling = useRef(false);
  const scrollingTimeout = useRef();

  const { windowWidth, windowHeight } = windowDimensions;

  const contact = useRef();
  const content = useRef();
  const main = useRef();
  const openingHours = useRef();

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (content.current && contact.current && openingHours.current && main.current && pathname !== '/privacy-policy') {
      if (isScrolling.current === false) {
        if (isInitialized === false) {
          setIsInitialized(true);
          setTimeout(() => {
            if (pathname === '/') {
              content.current.scroll(0, 0);
            } else if (pathname === '/contact') {
              content.current.scroll({ left: 0, top: contact.current.offsetTop, behavior: 'smooth' });
            } else if (pathname === '/opening-hours') {
              content.current.scroll({ left: 0, top: openingHours.current.offsetTop, behavior: 'smooth' });
            }
          }, 400);
        } else {
          if (pathname === '/') {
            content.current.scroll({ left: 0, top: 0, behavior: 'smooth' });
          } else if (pathname === '/contact') {
            content.current.scroll({ left: 0, top: contact.current.offsetTop, behavior: 'smooth' });
          } else if (pathname === '/opening-hours') {
            content.current.scroll({ left: 0, top: openingHours.current.offsetTop, behavior: 'smooth' });
          }
        }
      }
    } else {
      setIsInitialized(false);
    }
  }, [pathname, isInitialized]);

  useEffect(() => {

    const handleWindowResize = () => {
      setWindowDimensions({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
      });
    }

    const handleWindowResizeThrottle = _.throttle(handleWindowResize, 120);

    handleWindowResizeThrottle();
    window.addEventListener('resize', handleWindowResizeThrottle);

    const getSiteData = () => {
      fetchCockpitData('main', setMainData, false);
      fetchCockpitData('openingHours', setOpeningHoursData, false);
      fetchCockpitData('contact', setContactData, false);
      fetchCockpitData('privacyPolicy', setPrivacyPolicyData, false);
    }

    getSiteData();

    return () => {
      window.removeEventListener('resize', handleWindowResizeThrottle);
    }
  }, []);

  const handleScroll = (e) => {
    clearTimeout(scrollingTimeout.current);
    if (pathname !== '/privacy-policy') {
      const scrollTop = e.target.scrollTop;
      if (isScrolling.current === false) {
        isScrolling.current = true;
      }
      scrollingTimeout.current = setTimeout(() => {
        isScrolling.current = false;
      }, 200);
      if (content.current && contact.current && main.current && openingHours.current) {
        if (scrollTop + windowHeight > contact.current.offsetTop) {
          props.history.push('/contact');
        } else if (scrollTop + windowHeight > openingHours.current.offsetTop) {
          props.history.push('/opening-hours');
        } else {
          props.history.push('/');
        }
      }
    }
  }

  return (
    <div className={`App`}>
      <Header windowDimensions={windowDimensions} pathname={props.location.pathname} isScrolling={isScrolling} />
      <main
        className="content"
        ref={content}
        onScroll={handleScroll}
        style={{ height: windowDimensions.windowHeight + 'px' }}
      >
        <AnimatePresence>
          {
            props.location.pathname !== '/privacy-policy' &&
            <LandingPage {...props} mainData={mainData} openingHoursData={openingHoursData} contactData={contactData} windowDimensions={windowDimensions} key="landing page"
              main={main}
              openingHours={openingHours}
              contact={contact}
            />
          }
          <Switch key="routeswitcher">
            <Route key="privacy policy" exact path="/privacy-policy"
              render={(props) => (
                <PrivacyPolicy {...props} privacyPolicyData={privacyPolicyData} />
              )}
            />
          </Switch>
        </AnimatePresence>
      </main>
      {
        windowWidth >= 768 &&
        <Link to={`/privacy-policy`} className="link--privacy-policy">Privacy Policy</Link>
      }
    </div>
  );
}

const App = () => (
  <BrowserRouter base="/">
    <Route path="/" render={(props) => (
      <Content {...props} />
    )} />
  </BrowserRouter>
);

export default App;
