const OpeningHours = (props) => {

  const { openingHoursData, openingHours } = props;

  return (
    <section ref={openingHours}>
      {
        openingHoursData.openingHours &&
        openingHoursData.openingHours[0] &&
        <div className="openingHours__wrapper">
          <h2 className="openingHours__heading">Opening Hours</h2>
          <ul className="openingHours__list">
            {
              openingHoursData.openingHours.map(
                (item, i) => (
                  <li className="li--plain row openingHours__list__item" key={i}>
                    <div className="col-xxs-6">
                      <p className="openingHours__list__item__text openingHours__list__item__text--day">{item.value?.day}</p>
                    </div>
                    <div className="col-xxs-6">
                      <p className="openingHours__list__item__text openingHours__list__item__text--times">{item.value?.times}</p>
                    </div>
                  </li>
                )
              )
            }
          </ul>
        </div>
      }
    </section>
  )
}

export default OpeningHours;