import { useState, useEffect } from 'react';
import { getImage } from '../utils/cockpit';

const LazyloadImage = (props) => {

  const { image, maxWidth, maxHeight, isBackgroundImage } = props;
  const [imageSrcSmall, setImageSrcSmall] = useState('');
  const [imageSrcLarge, setImageSrcLarge] = useState('');
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  useEffect(() => {

    let img;
    const onImageLoad = () => {
      setImageIsLoaded(true);
    }

    if (image.path) {
      const path = image.path;

      getImage(path, 16, 16, 30, [], (imageUrlSmall) => {
        setImageSrcSmall(imageUrlSmall);
        getImage(path,
          maxWidth ? maxWidth : 2500,
          maxHeight ? maxHeight : 2500,
          30,
          [],
          (imageUrl) => {
            const url = imageUrl;
            setImageSrcLarge(url);
            img = document.createElement('img');
            img.addEventListener('load', onImageLoad);
            img.src = url;
          }
        )
      });
    }

    return () => {
      if (img) {
        img.removeEventListener('load', onImageLoad);
      }
    }
  }, [image.path, maxWidth, maxHeight]);

  if (image.path && image.path !== '') {
    if (isBackgroundImage === true) {
      return (
        <div
          className={`background-image--lazyload${imageIsLoaded === true ? ' loaded' : ''}`}
          style={{
            backgroundImage: `url(${imageIsLoaded === true ? imageSrcLarge : imageSrcSmall})`
          }}
        />
      )
    } else {
      return (
        <img
          className={`image--lazyload${imageIsLoaded === true ? ' loaded' : ''}`}
          src={imageIsLoaded === true ? imageSrcLarge : imageSrcSmall}
          alt={image.title}
        />
      )
    }
  } else {
    return null
  }
}

export default LazyloadImage;