import parse from 'html-react-parser';
import LazyLoadImage from './LazyloadImage';

const Main = (props) => {

  const { mainData, main } = props;

  return (
    <section ref={main}>
      <div className="row">
        <div className="col-xxs-12">
          {
            mainData.mainImage &&
            <div className="main__image__wrapper">
              <LazyLoadImage image={mainData.mainImage} />
            </div>
          }
          {
            mainData.mainText &&
            <div className="main__text__wrapper">
              <div className="main__text__inner">
                {parse(mainData.mainText)}
              </div>
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default Main;