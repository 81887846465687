import { motion } from 'framer-motion';
import parse from 'html-react-parser';

const LandingPage = (props) => {

  const { privacyPolicyData } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="view view--privacy-policy"
    >
      <section>
        {
          privacyPolicyData.privacyPolicy &&
          parse(privacyPolicyData.privacyPolicy)
        }
      </section>
    </motion.div>
  )
}

export default LandingPage;