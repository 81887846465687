import { Link } from 'react-router-dom';

const Header = (props) => {

  const { pathname, isScrolling } = props;

  return (
    <header className="header">
      <nav className="menu">
        <Link
          className={`menu__item${pathname === '/' ? ' active' : ''}`}
          to="/"
          onClick={() => {isScrolling.current = false; }}
        >
          <h1 className="heading--main">Thornbury Eye Centre</h1>
        </Link>
        <Link
          className={`menu__item${pathname === '/opening-hours' ? ' active' : ''}`}
          to="/opening-hours"
          onClick={() => {isScrolling.current = false; }}
        >
          Opening Hours
        </Link>
        <Link
          className={`menu__item${pathname === '/contact' ? ' active' : ''}`}
          to="/contact"
          onClick={() => {isScrolling.current = false; }}
        >
          Contact
        </Link>
      </nav>
    </header>
  )
}

export default Header;