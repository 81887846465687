import parse from 'html-react-parser';

const Contact = (props) => {

  const { contactData, contact } = props;

  return (
    <section ref={contact}>
      <div className="contact__wrapper">
        <div className="row">
          <div className="col-xxs-12">
            <h2 className="contact__heading">Contact</h2>
          </div>
          <div className="col-xxs-12 col-xs-6">
            {
              contactData.telephoneNumbers &&
              contactData.telephoneNumbers[0] &&
              <ul className="contact__list">
                {
                  contactData.telephoneNumbers.map(
                    (item, i) => (
                      <li className="li--plain row contact__list__item" key={i}>
                        <div className="col-xxs-12">
                          <p className="contact__list__item__text contact__list__item__text--telephoneNumber">{item.value}</p>
                        </div>
                      </li>
                    )
                  )
                }
              </ul>
            }
              {
                contactData.emailAddresses &&
                contactData.emailAddresses[0] &&
                <ul className="contact__list">
                  {
                    contactData.emailAddresses.map(
                      (item, i) => (
                        <li className="li--plain row contact__list__item" key={i}>
                          <div className="col-xxs-12">
                            <p className="contact__list__item__text contact__list__item__text--telephoneNumber">{item.value}</p>
                          </div>
                        </li>
                      )
                    )
                  }
                </ul>
              }
            </div>
          {
            contactData.streetAddress &&
            <div className="col-xxs-12 col-xs-6">
              <p className="contact__list__item__text contact__list__item__text--streetAddress">{parse(contactData.streetAddress)}</p>
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default Contact;